import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'

import type { TApiChapterDetailsResponse } from './responses/chapter'
import { TransferError } from '../Utils/Error'
import { createRetry } from './utils'
import transform from './transformers/chapter'

export default function useChapter(chapterId: number, configuration?: SWRConfiguration<TApiChapterDetailsResponse>) {
  const {
    data: chapterPayload,
    error,
    mutate,
  } = useSWR<TApiChapterDetailsResponse, TransferError>(`chapter/${chapterId}`, configuration)

  return {
    chapter: chapterPayload ? transform(chapterPayload) : undefined,
    error,
    mutate,
    retry: createRetry(mutate),
  }
}
