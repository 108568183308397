import './Checkboxes.css'

export type TOption = {
  /** Value */
  id: number
  /** Label */
  name: string
}

/**
 * Gmaps-styled category filer
 */
const Checkboxes: preact.FunctionComponent<{
  label: string,
  values: number[],
  options: TOption[],
  onChange: (value: number[]) => void,
}> = ({
  label,
  values,
  options,
  onChange,
}) => {

  const handleChange = (event: JSX.TargetedEvent<HTMLInputElement, Event>): void => {
    if (event.target instanceof HTMLInputElement) {
      const id = Number.parseInt(event.target.value, 10)

      const ids = event.target.checked
        ? [...values, id]
        : values.filter(v => v !== id)

      onChange(ids)
    }
  }

  return (
    <fieldset className="iyp-checkboxes">
      {options.map(({ id, name}) =>
        <label
          key={id}
          className={`iyp-checkboxes__option ${values.includes(id) ? 'iyp-checkboxes__option--checked' : ''}`}
        >
          <input
            value={id}
            type="checkbox"
            checked={values.includes(id)}
            onChange={handleChange}
          />
          <span className="iyp-checkboxes__label">
            {name}
          </span>
        </label>
      )}
    </fieldset>
  )
}

export default Checkboxes
