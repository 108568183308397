import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'

import type { TApiCityDetailsResponse } from './responses/city'
import { TransferError } from '../Utils/Error'
import { createRetry } from './utils'
import transform from './transformers/city'

export default function useCity(cityId?: number, configuration?: SWRConfiguration<TApiCityDetailsResponse>) {
  const {
    data: cityPayload,
    error,
    mutate,
  } = useSWR<TApiCityDetailsResponse, TransferError>(cityId ? `city/${cityId}` : undefined, configuration)

  return {
    city: cityPayload ? transform(cityPayload) : undefined,
    error,
    mutate,
    retry: createRetry(mutate),
  }
}
