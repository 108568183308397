import { useEffect, useRef } from 'preact/hooks'

import type { ControlOptions } from 'leaflet'
import { DomEvent } from 'leaflet'

import type { TFilterType } from '../FilterContext/FilterContext'
import { useFilterState } from '../FilterContext/FilterContext'
// import useCity from '../../data/use-city'
import useChapter from '../../data/use-chapter'
import Select from '../Select/Select'
import Checkboxes from '../Checkboxes/Checkboxes'
import LeafletControlPosition from '../LeafletControlPosition/LeafletControlPosition'

import './FilterControl.css'

/**
 * Filter control
 */
const FilterControl: preact.FunctionComponent<{
  cityId?: number,
  /** Chapter ID which will be used to get available options */
  chapterId: number,
  /** Filter types to show. For example on Featured page it does not make sense to use feature filters */
  types: TFilterType[],
} & ControlOptions> = ({
  cityId,
  chapterId,
  types = ['chapter', 'categories', 'districts', 'amenities'],
  position = 'bottomleft',
}) => {
  // Fetch available chapters
  // const { city } = useCity(cityId)
  // Fetch available filters
  const { chapter } = useChapter(chapterId)

  // Get current filter state
  const [ filter, setFilter ] = useFilterState()

  // Prevent map pan/ zoom on control element
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    containerRef.current && DomEvent
      .disableClickPropagation(containerRef.current)
      .disableScrollPropagation(containerRef.current)
  }, [])

  return (
    <LeafletControlPosition position={position}>
      <div
        className="leaflet-control leaflet-bar iyp-filter-control"
        ref={containerRef}
      >
        <form>
          {/** Chapter switch
          {types.includes('chapter') && city &&
            <div className="iyp-select">
              <label className="iyp-select__label">
                {'Chapter'}
              </label>
              <select
                value={filter.chapter ?? undefined}
                onChange={ev => {
                  if (ev.target instanceof HTMLSelectElement) {
                    setFilter({
                      ...filter,
                      chapter: Number.parseInt(ev.target.value, 10),
                      // Reset categories which depend on chapter
                      categories: [],
                    })
                  }
                }}
              >
                {city.chapters.map(chapter =>
                  <option
                    key={chapter.id}
                    value={chapter.id}
                  >
                    {chapter.name}
                  </option>
                )}
              </select>
            </div>
          }

          {/** Categories */}
          {types.includes('categories') &&
            <Checkboxes
              label={'Categories'}
              values={filter.categories}
              options={chapter?.categories ?? []}
              onChange={categories => setFilter({ ...filter, categories })}
            />
          }

          {/** Districts */}
          {/*
          {types.includes('districts') &&
            <Select
              label={'Districts'}
              values={filter.districts}
              options={chapter?.districts ?? []}
              onChange={districts => setFilter({ ...filter, districts })}
            />
          }
          */}

          {/** Features */}
          {/*
          {types.includes('features') &&
            <Select
              label={'Features'}
              values={filter.features}
              options={city?.features ?? []}
              onChange={features => setFilter({ ...filter, features })}
            />
          }
          */}
        </form>
      </div>
    </LeafletControlPosition>
  )
}

export default FilterControl
