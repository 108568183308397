import useChapter from '../../data/use-chapter'
import useVenueList from '../../data/use-venue-list'
import { hasShowCategoryFilter } from '../../Utils/url'
import { useFilterState } from '../../Components/FilterContext/FilterContext'

import Map from '../../Components/Map/Map'
import Spinner from '../../Components/Spinner/Spinner'
import Error from '../../Components/Error/Error'
import PageHeading from '../../Components/PageHeading/PageHeading'

/**
 * Partner Chapter page
 */
const PartnerPage: preact.FunctionComponent<{
  params: { chapterId: string }
}> = ({
  params: {
    chapterId: chapterIdString
  }
}) => {
  const chapterId = Number.parseInt(chapterIdString, 10)
  const showCategoryFilter = hasShowCategoryFilter(window.location.search)

  const [ filter ] = useFilterState()

  const {
    chapter,
    error: cityError,
    retry: cityRetry,
  } = useChapter(chapterId)

  // Get all venues
  const {
    venues,
    error: venuesError,
    retry: venuesRetry,
  } = useVenueList({
    chapterId,
    categoryId: filter.categories,
  })

  const transferError = venuesError ?? cityError
  const retry = venuesError ? venuesRetry : cityRetry

  return (
    <section className="iyp-page iyp-page--map">
      <PageHeading
        label="Partner (Chapter)"
      />
      {transferError
        ? <Error error={transferError} retryFn={retry} />
        : chapterId && venues
          ?
            <Map
              venuesList={venues}
              filter={showCategoryFilter
                ? { chapterId, types: ['categories'] }
                : undefined
              }
            />
          : <Spinner />

      }
    </section>
  )
}

export default PartnerPage
