import { useState, useMemo } from 'preact/hooks'

import { getUrl } from '../../Utils/url'
import { useEnv } from '../../Components/EnvContext/EnvContext'
import URL_PARAM from '../../models/url-params'

import routes from '../../routes'

import Button from '../../Components/Button/Button'

import './HomePage.css'

/**
 * Home page
 */
const HomePage: preact.FunctionComponent = () => {
  const { BASE_URL } = useEnv()

  const [ route, setRoute ] = useState<string>('/:route')
  const [ showFeaturedOnly, setShowFeaturedOnly ] = useState<boolean>(false)
  const [ showCategoryFilter, setShowCategoryFilter ] = useState<boolean>(false)

  /**
   * Handle route change
   */
  const handleRouteChange = (event: JSX.TargetedEvent<HTMLInputElement>): void => {
    if (event.target instanceof HTMLInputElement) {
      setRoute(event.target.value)
      setShowFeaturedOnly(false)
      setShowCategoryFilter(false)
    }
  }

  /**
   * Handle show featured only toggle
   */
  const handleToggleShowFeaturedOnly = (event: JSX.TargetedEvent<HTMLInputElement>): void => {
    if (event.target instanceof HTMLInputElement) {
      setShowFeaturedOnly(event.target.checked)
    }
  }

  /**
   * Handle show category filter
   */
  const handleToggleCategoryFilter = (event: JSX.TargetedEvent<HTMLInputElement>): void => {
    if (event.target instanceof HTMLInputElement) {
      setShowCategoryFilter(event.target.checked)
    }
  }

  /**
   * Handle copy button click
   */
  const handleCopyButtonClick = (): void => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(codeSnippet)
    }
  }

  /**
   * Resolve widget url on change
   */
  const widgetUrl = useMemo(
    () => {
      const url = getUrl(BASE_URL, route)

      if (showFeaturedOnly) {
        url.searchParams.set(URL_PARAM.SHOW_FEATURED_ONLY, 'true')
      }

      if (showCategoryFilter) {
        url.searchParams.set(URL_PARAM.SHOW_CATEGORY_FILTER, 'true')
      }

      return url
    },
    [BASE_URL, route, showFeaturedOnly, showCategoryFilter]
  )

  const codeSnippet = `
<iframe
  class="iyp-map-widget"
  src="${widgetUrl}"
  allow="fullscreen; geolocation"
  allowfullscreen
  loading="lazy"
  referrerpolicy="unsafe-url"
  style="width:100%; height: 450px; border: none;"
></iframe>`
    .trim()

  return (
    <section className="iyp-page iyp-page--content">
      <h1 className="iyp-h1">
        inyourpocket.com Map Widget
      </h1>

      {/** Usage */}
      <h2 className="iyp-h2">
        {'Usage'}
      </h2>
      <p>
        Use following HTML code replacing corresponding :parameter
      </p>
      <div className="iyp-code">
        <textarea
          className="iyp-code__snippet"
          readOnly
          disabled={route === '/:route'}
          rows={codeSnippet.split('\n').length}
        >
          {codeSnippet}
        </textarea>
        <Button
          text={'Copy to clipboard'}
          className="iyp-code__button"
          disabled={route === '/:route'}
          onClick={handleCopyButtonClick}
        />
      </div>

      <form>
        {/** Available routes */}
        <h2 className="iyp-h2">
          {'Available routes'}
        </h2>
        <dl className="iyp-description-list">
          {/** Chapter */}
          <dt>
            {'Chapter'}
          </dt>
          <dd>
            <label className="iyp-route-label">
              <input
                type="radio"
                name="route"
                value={routes.chapter}
                onChange={handleRouteChange}
              />
              <span>
                {routes.chapter}
              </span>
            </label>
            {/** Chapter Params */}
            <fieldset
              className="iyp-params-fieldset"
              disabled={route !== routes.chapter}
            >
              <legend class="iyp-params-legend">
                {'Parameters'}
              </legend>
              <label>
                <input
                  name={`chapter[${URL_PARAM.SHOW_FEATURED_ONLY}]`}
                  type="checkbox"
                  value="1"
                  checked={showFeaturedOnly && route === routes.chapter}
                  onChange={handleToggleShowFeaturedOnly}
                />
                <span>
                  {'Show only featured venues'}
                </span>
              </label>
            </fieldset>
          </dd>

          {/** Categories */}
          <dt>
            {'Category'}<br />
            <small>
              {'To use multiple categories, separate IDs with comma'}
            </small>
          </dt>
          <dd>
            <label className="iyp-route-label">
              <input
                type="radio"
                name="route"
                value={routes.category}
                onChange={handleRouteChange}
              />
              <span>
                {routes.category}
              </span>
            </label>
            {/** Category Params */}
            <fieldset
              className="iyp-params-fieldset"
              disabled={route !== routes.category}
            >
              <legend class="iyp-params-legend">
                {'Parameters'}
              </legend>
              <label>
                <input
                  name={`category[${URL_PARAM.SHOW_FEATURED_ONLY}]`}
                  type="checkbox"
                  value="1"
                  checked={showFeaturedOnly && route === routes.category}
                  onChange={handleToggleShowFeaturedOnly}
                />
                <span>
                  {'Show only featured venues'}
                </span>
              </label>
            </fieldset>
          </dd>

          {/** Featured Article */}
          <dt>
            {'Featured Article'}
          </dt>
          <dd>
            <label className="iyp-route-label">
              <input
                type="radio"
                name="route"
                value={routes.feature}
                onChange={handleRouteChange}
              />
              <span>
                {routes.feature}
              </span>
            </label>
          </dd>

          {/** Venue */}
          <dt>
            {'Venue'}
          </dt>
          <dd>
            <label className="iyp-route-label">
              <input
                type="radio"
                name="route"
                value={routes.venue}
                onChange={handleRouteChange}
              />
              <span>
                {routes.venue}
              </span>
            </label>
            {/** Venue Params */}
            <fieldset
              className="iyp-params-fieldset"
              disabled={route !== routes.venue}
            >
              <legend class="iyp-params-legend">
                {'Parameters'}
              </legend>
              <label>
                <input
                  name={`venue[${URL_PARAM.SHOW_FEATURED_ONLY}]`}
                  type="checkbox"
                  value="1"
                  checked={showFeaturedOnly && route === routes.venue}
                  onChange={handleToggleShowFeaturedOnly}
                />
                <span>
                  {'Show only featured associated venues'}
                </span>
              </label>
            </fieldset>
          </dd>

          {/** Event */}
          <dt>
            {'Event'}
          </dt>
          <dd>
            <label className="iyp-route-label">
              <input
                type="radio"
                name="route"
                value={routes.event}
                onChange={handleRouteChange}
              />
              <span>
                {routes.event}
              </span>
            </label>
            {/** Event Params */}
            <fieldset
              className="iyp-params-fieldset"
              disabled={route !== routes.event}
            >
              <legend class="iyp-params-legend">
                {'Parameters'}
              </legend>
              <label>
                <input
                  name={`event[${URL_PARAM.SHOW_FEATURED_ONLY}]`}
                  type="checkbox"
                  value="1"
                  checked={showFeaturedOnly && route === routes.event}
                  onChange={handleToggleShowFeaturedOnly}
                />
                <span>
                  {'Show only featured associated venues'}
                </span>
              </label>
            </fieldset>
          </dd>

          {/** Partner (Chapter) */}
          <dt>
            {'Partner (Chapter)'}
          </dt>
          <dd>
            <label className="iyp-route-label">
              <input
                type="radio"
                name="route"
                value={routes.partnerChapter}
                onChange={handleRouteChange}
              />
              <span>
                {routes.partnerChapter}
              </span>
            </label>
            <fieldset
              className="iyp-params-fieldset"
              disabled={route !== routes.partnerChapter}
            >
              <legend class="iyp-params-legend">
                {'Parameters'}
              </legend>
              <label>
                <input
                  name={`partner-chapter[${URL_PARAM.SHOW_CATEGORY_FILTER}]`}
                  type="checkbox"
                  value="1"
                  checked={showCategoryFilter && route === routes.partnerChapter}
                  onChange={handleToggleCategoryFilter}
                />
                <span>
                  {'Show category filter'}
                </span>
              </label>
            </fieldset>
          </dd>
        </dl>
      </form>
    </section>
  )
}

export default HomePage
