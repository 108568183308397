/**
 * @link https://data-api-v1-staging.inyourpocket.com/chapter/138
 */

import type { TApiChapterDetailsResponse } from '../responses/chapter'

type IChapter = {
  id: number,
  name: string,
  categories: Array<{
    id: number,
    name: string,
  }>
}

export default function transformChapter ({ data }: TApiChapterDetailsResponse): IChapter {
  return {
    id: data.chapter.chapter_id,
    name: data.chapter.name,
    categories: data.categoryList.map(categoryData => ({
      id: categoryData.category_id,
      name: categoryData.name,
    }))
  }
}
