/**
 * Widget url query param flags
 */
enum URL_PARAM {
  /** Show featured only venues; 'true'|'false' */
  SHOW_FEATURED_ONLY = 'show-featured-only',
  SHOW_CATEGORY_FILTER = 'show-category-filter',
}

export default URL_PARAM
