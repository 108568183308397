import type { ControlOptions } from 'leaflet'

import LeafletControlPosition from '../LeafletControlPosition/LeafletControlPosition'

import logoUrl from '../../images/logo-essentials.svg'
import logoWhiteUrl from '../../images/logo-essentials--white.svg'

import './BrandingControl.css'

/**
 * Branding react control
 * @see https://react-leaflet.js.org/docs/example-react-control/
 */
const BrandingControl: preact.FunctionComponent<ControlOptions> = ({
  position = 'topright',
}) =>
  <LeafletControlPosition position={position}>
    <div className="leaflet-control leaflet-bar iyp-branding">
      <a
        className="iyp-branding__anchor"
        href="https://www.inyourpocket.com"
        title="Open inyourpocket.com web in new tab"
        rel="next"
        referrerpolicy="origin-when-cross-origin"
        target="_parent"
      >
        <picture>
          <source
            className="iyp-branding__image"
            media="(prefers-color-scheme: dark)"
            srcset={logoWhiteUrl}
          />
          <img
            className="iyp-branding__image"
            src={logoUrl}
            width={64}
            height={57}
            alt="inyourpocket.com"
          />
        </picture>
      </a>
    </div>
  </LeafletControlPosition>

export default BrandingControl
