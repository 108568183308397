/**
 * Transformer for venue details path
 */
import type { TVenueDetails as TApiVenueDetailsResponse } from '../models/venue'
import type { IVenue, IService, ISocialLink } from '../../models/venue.interfaces'

import { socialLinkTypes, SOCIAL_LINK_TYPE } from '../../models/venue.constants'

import {
  getUrl,
  getAttribs,
  isValid,
  isListItemValid,
  isPremium,
} from './utils/venue'

export default function transformDetails (payload: TApiVenueDetailsResponse, showFeaturedOnly = false): IVenue | null {

  const {
    venue,
    city,
  } = payload.data

  // Validate coodinates
  if (!isValid(venue)) {
    return null
  }

  let associatedVenues = payload.data.associatedVenues

  if (showFeaturedOnly) {
    associatedVenues = associatedVenues.filter(isPremium)
  }

  return {
    id: venue.venue_id,
    name: venue.name,
    location: [
      venue.location.lat,
      venue.location.lon,
    ],
    attribs: getAttribs(venue),
    address: venue.location.address ?? '-',
    phoneNumber: venue.phone,
    lead: venue.lead ?? venue.body,
    description: venue.body,
    url: getUrl(venue),
    photos: payload.data.gallery,
    services: payload.data.serviceList as IService[],
    website: venue.links.website,
    open: undefined,// TODO
    openHoursText: !venue.open.isClosed
      ? venue.open.open_hours_present
      : null,
    priceRange: venue.pricing.average_prices,
    // White list handled social links
    socials: Object.entries(venue.links).reduce<ISocialLink[]>(
      (prev, [type, url]) =>
        url && socialLinkTypes.includes(type as SOCIAL_LINK_TYPE)
          ? [ ...prev, { type, url }]
          : prev,
      []
    ),
    associated: associatedVenues
      .filter(isListItemValid)
      .map(associatedVenue => ({
        id: associatedVenue.venue_id,
        name: associatedVenue.name,
        location: [
          associatedVenue.lat,
          associatedVenue.lon,
        ],
        attribs: getAttribs(associatedVenue),
        url: getUrl(associatedVenue),
      })),
    cityId: city.city_id,
  }
}
