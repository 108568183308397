import type { ControlPosition, ControlOptions } from 'leaflet'

/** Classes used by Leaflet to position controls */
const POSITION_CLASSNAME: Record<ControlPosition, string> = {
  bottomleft:  'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft:     'leaflet-top leaflet-left',
  topright:    'leaflet-top leaflet-right',
} as const

/**
 * Leaflet control position wrapper
 */
const LeafletControlPosition: preact.FunctionComponent<ControlOptions> = ({
  position = 'topleft',
  children,
}) =>
  <div className={POSITION_CLASSNAME[position] ?? POSITION_CLASSNAME.topleft}>
    {children}
  </div>

export default LeafletControlPosition
