import { Router, Switch, Route } from 'wouter-preact'
import type { SWRConfiguration } from 'swr'
import { SWRConfig } from 'swr'

import { createFetcher } from '../../Utils/fetcher'
import { getUrl } from '../../Utils/url'
import { FilterStateProvider } from '../FilterContext/FilterContext'
import { TransferError, ConnectError } from '../../Utils/Error'
import { EnvProvider } from '../EnvContext/EnvContext'
import ModalProvider from '../Modal/ModalProvider'

import HomePage from '../../Pages/Home/HomePage'
import ChapterPage from '../../Pages/Chapter/ChapterPage'
import CategoryPage from '../../Pages/Category/CategoryPage'
import FeaturePage from '../../Pages/Feature/FeaturePage'
import VenuePage from '../../Pages/Venue/VenuePage'
import EventPage from '../../Pages/Event/EventPage'
import PartnerChapterPage from '../../Pages/PartnerChapter/PartnerChapterPage'
import PartnerCityPage from '../../Pages/PartnerCity/PartnerCityPage'
import NotFoundPage from '../../Pages/NotFound/NotFoundPage'

import routes from '../../routes'

import './App.css'

/**
 * App
 */
const App: preact.FunctionComponent<{
  env: ImportMetaEnv
}> = ({
  env,
}) => {
  /** @link https://github.com/vercel/swr/blob/1.0.0/src/utils/config.ts */
  const swrConfig: SWRConfiguration = {
    revalidateOnFocus: false,
    shouldRetryOnError: (error: TransferError) =>
      // Retry only on connection errors
      error instanceof ConnectError,
    fetcher: createFetcher(env.VITE_API_URL),
  }

  // Resolve base url (any value supported by vite); for wouter trim trailing /
  const baseUrl = getUrl(env.BASE_URL)

  return (
    <EnvProvider env={env}>
      <SWRConfig value={swrConfig}>
        <FilterStateProvider>
          <main className="iyp-app">
            <ModalProvider>
              <Router base={baseUrl.pathname.slice(0, -1)}>
                <Switch>
                  <Route path={routes.home} component={HomePage} />
                  <Route path={routes.chapter} component={ChapterPage} />
                  <Route path={routes.category} component={CategoryPage} />
                  <Route path={routes.feature} component={FeaturePage} />
                  <Route path={routes.venue} component={VenuePage} />
                  <Route path={routes.event} component={EventPage} />
                  <Route path={routes.partnerChapter} component={PartnerChapterPage} />
                  {/** Backward compat */}
                  <Route path={'/partner-chapter/:chapterId'} component={PartnerChapterPage} />
                  {/** Backward compat */}
                  <Route path={routes.partnerCity} component={PartnerCityPage} />
                  <Route path="/:path*" component={NotFoundPage} />
                </Switch>
              </Router>
            </ModalProvider>
          </main>
        </FilterStateProvider>
      </SWRConfig>
    </EnvProvider>
  )
}

export default App
