import URL_PARAM from '../models/url-params'

/**
 * Get URL for route
 */
export function getUrl(envBaseUrl: string, route?: string): URL {
  const baseUrl = new URL(envBaseUrl, window.location.origin)

  return route
    ? new URL(baseUrl.pathname + route.slice(1), baseUrl)
    : baseUrl
}

/**
 * Get url flag for show featured only
 */
export function hasShowFeaturedOnly(search: string): boolean {
  return new URLSearchParams(search).get(URL_PARAM.SHOW_FEATURED_ONLY) === 'true'
}

/**
 * Get url flag for show category filter
 */
export function hasShowCategoryFilter(search: string): boolean {
  return new URLSearchParams(search).get(URL_PARAM.SHOW_CATEGORY_FILTER) === 'true'
}
