/**
 * Filter state context
 */
import { createContext } from 'preact'
import { useState, useContext } from 'preact/hooks'
import type { StateUpdater } from 'preact/hooks'

// Filter type
export type TFilterType = 'chapter' | 'categories' | 'districts' | 'amenities'

export type TState = {
  chapter: number | null,
  categories: number[],
  districts: number[],
  amenities: number[],
}

type TUseStateResult = [
  TState,
  StateUpdater<TState>,
]

/**
 * Initial state
 */
const initialState: TState = {
  chapter: null,
  categories: [],
  districts: [],
  amenities: [],
}

/**
 * Context
 */
export const FilterStateContext = createContext<TUseStateResult>([
  initialState,
  () => null,
])

/**
 * Hook
 */
export function useFilterState(): TUseStateResult {
  return useContext(FilterStateContext)
}

/**
 * Provider
 */
export const FilterStateProvider: preact.FunctionComponent = ({
  children
}) => {
  const useStateResult = useState<TState>(initialState)

  return (
    <FilterStateContext.Provider value={useStateResult}>
      {children}
    </FilterStateContext.Provider>
  )
}
