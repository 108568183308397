/**
 * Modal
 * @link https://dev.to/alexandprivate/your-next-react-modal-with-your-own-usemodal-hook-context-api-3jg7
 *
 * Note: context has only one, immutable property to prevent re-renders caused by context updates
 * @link https://github.com/facebook/react/issues/15156#issuecomment-474590693
 */
import { createContext } from 'preact'
import { useContext, useState } from 'preact/hooks'

import Modal from './Modal'

/**
 * Content type
 */
export type IContent = {
  /** Header text */
  header: string
  /** Body */
  body: preact.ComponentChild
  /** External URL */
  url?: string
  /** Footer */
  footer?: preact.ComponentChild
}

/**
 * Set content modal function
 */
export type TSetContent = (content: IContent|null) => void

/**
 * Modal API
 */
type IModalApiContext = TSetContent

/**
 * Modal context
 */
const ModalContext = createContext<IModalApiContext>(
  () => undefined,
)

/**
 * Modal hook
 */
export function useModal(): IModalApiContext {
  return useContext(ModalContext)
}

/**
 * Modal context provider
 */
const ModalContextProvider: preact.FunctionComponent = ({ children }) => {
  const [ content, setContent ] = useState<IContent|null>(null)

  return (
    <ModalContext.Provider value={setContent}>
      <Modal
        content={content}
        onClose={() => setContent(null)}
      />
      { children }
    </ModalContext.Provider>
  )
}

export default ModalContextProvider

/**
 * HOC to pass setModalContent as a component prop, when using wrap component with memo
 * @link https://stackoverflow.com/a/55730151/1012616
 */
export function withSetModalContent<P>(WrappedComponent: preact.ComponentType<P>) {
  const Wrapped = (props: Omit<P, 'setModalContent'>) => {
    const setContent = useModal()

    return (
      <WrappedComponent
        setModalContent={setContent}
        {...props as P}
      />
    )
  }

  Wrapped.displayName = `withSetModalContent(${WrappedComponent.displayName})`

  return Wrapped
}
