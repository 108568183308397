import { AppError } from '../../Utils/Error'
import useVenue from '../../data/use-venue'
import { hasShowFeaturedOnly } from '../../Utils/url'

import Map from '../../Components/Map/Map'
import Spinner from '../../Components/Spinner/Spinner'
import Error from '../../Components/Error/Error'
import PageHeading from '../../Components/PageHeading/PageHeading'

/**
 * Venue page
 */
const VenuePage: preact.FunctionComponent<{
  params: {
    venueId: string
  }
}> = ({
  params: {
    venueId,
  },
}) => {
  const { venue, error: transferError, retry } = useVenue(
    Number.parseInt(venueId, 10),
    hasShowFeaturedOnly(window.location.search)
  )

  // Validate venue (coords)
  const invalidVenueError: Error | undefined = venue === null
    ? new AppError('Invalid venue')
    : undefined

  return (
    <section className="iyp-page iyp-page--map">
      <PageHeading
        label="Venue"
        features={[
          'Current venue (violet, black outline)',
          'Associated venues (violet)',
        ]}
      />

      {transferError
        ? <Error error={transferError} retryFn={retry} />
        : invalidVenueError
          ? <Error error={invalidVenueError} />
          : venue
            ?
              <Map
                venue={venue}
                venuesList={venue.associated}
              />
            : <Spinner />
      }
    </section>
  )
}

export default VenuePage
