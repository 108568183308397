import { MapContainer, TileLayer } from 'react-leaflet'
import { Map as LeafletMap, Control, DomUtil } from 'leaflet'
import type { ErrorEvent, FullscreenXEvent } from 'leaflet'
import 'leaflet.locatecontrol'

import LocationIconHtml from 'ionicons/dist/svg/location-outline.svg?raw'

import type { IVenue, IVenueListItem } from '../../models/venue.interfaces'
import Error from '../Error/Error'
import { AppError } from '../../Utils/Error'
import { getEmbeddedMode, EMBEDDED_MODE } from '../../Utils/widget'
import { useEnv } from '../EnvContext/EnvContext'
import { useModal } from '../Modal/ModalProvider'
import type { TSetContent as TSetModalContent } from '../Modal/ModalProvider'
import MarkersGroup from '../Marker/MarkersGroup'
import Marker from '../Marker/Marker'
import BrandingControl from '../BrandingControl/BrandingControl'
import FilterControl from '../FilterControl/FilterControl'
import type { TFilterType } from '../FilterContext/FilterContext'

import 'leaflet/dist/leaflet.css'
import 'leaflet.locatecontrol/dist/L.Control.Locate.css'

import '../../leaflet/control/FullscreenX'
import './Map.css'

/**
 * Tile layer props
 * @see https://leaflet-extras.github.io/leaflet-providers/preview/
 */
const tileLayer = {
  // /*
  // OpenStreetMap
  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  // */

  /*
  // Carto Light (same as CartoDB)
  url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
  attribution: '© OpenStreetMap, © CartoDB',
  // */

  /*
  // Debug
  url: 'http://localhost:3000',
  attribution: '© test',
  // */
}

/**
 * Note: Browser.mobile is true on chrome when inside an iframe (leaflet 1.7.1)
 *       Browser.touch is broken too
 */
const isTouchDeviceMql = window.matchMedia('(pointer: coarse)')

/**
 * Map container (Venue)
 * Explicitely set maxZoom to default 18 as it's lost after update to preact@10.6.3
 *   and it's max value that tiles provide
 */
const Map: preact.FunctionComponent<{
  /** Venues to show */
  venuesList: IVenueListItem[],
  /** Main venue if applicable */
  venue?: IVenue,
  /** City id to use for filters */
  filter?: {
    cityId?: number,
    chapterId: number,
    types: TFilterType[],
  },
}> = ({
  venue,
  venuesList,
  filter = undefined,
}) => {
  const { VITE_IYP_ORIGIN: iypOrigin } = useEnv()
  const setModalContent = useModal()

  // No points to show
  const noRelatedVenuesError = !filter && !venue && !venuesList.length
    ? new AppError('No venues to show')
    : undefined

  if (noRelatedVenuesError) {
    return <Error error={noRelatedVenuesError} />
  }

  const mode = getEmbeddedMode(iypOrigin)

  // Prevent scrolling when inside an iframe on touch devices
  const useScrollLock = mode !== EMBEDDED_MODE.STANDALONE && isTouchDeviceMql.matches

  return (
    <MapContainer
      bounds={undefined}
      dragging={!useScrollLock}
      maxZoom={18}
      tap={false}
      scrollWheelZoom={true}
      whenCreated={map => handleMapCreated(map, useScrollLock, setModalContent)}
      zoomControl={false}
    >
      {/** Tiles layer */}
      <TileLayer {...tileLayer} />

      {/** Branding */}
      <BrandingControl position="topright" />

      {/** Filter */}
      {filter &&
        <FilterControl
          cityId={filter.cityId}
          chapterId={filter.chapterId}
          types={filter.types}
          position="topleft"
        />
      }

      {/** Current venue */}
      {venue &&
        <Marker
          current
          venueListItem={venue}
        />
      }

      {/** Markers */}
      <MarkersGroup
        current={venue}
        venuesList={venuesList}
      />
    </MapContainer>
  )
}

/**
 * The whenCreated map event handler
 */
function handleMapCreated (map: LeafletMap, useScrollLock: boolean, setModalContent: TSetModalContent): void {
  // Zoom control with custom position
  new Control.Zoom({ position: 'bottomright' })
    .addTo(map)

  // Locate control
  new Control.Locate({
    position: 'bottomright',

    // Classnames
    icon: 'iyp-locate-icon', // 'leaflet-control-locate-location-arrow'
    iconLoading: 'iyp-locate-icon leaflet-control-locate-spinner', // 'leaflet-control-locate-spinner'

    // Custom cb
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createButtonCallback: (container: HTMLDivElement, options: any): object => {
      // Create button
      const link = DomUtil.create('a', 'leaflet-bar-part leaflet-bar-part-single', container)

      link.title = options.strings.title
      link.href = '#'

      // Create icon element
      const icon: HTMLSpanElement = DomUtil.create(options.iconElementTag, options.icon, link)

      icon.insertAdjacentHTML('afterbegin', LocationIconHtml)

      return { link, icon }
    },

    /**
     * See GeolocationPositionError
     * Note: GeolocationPositionError object is N/A in Safari
     */
    onLocationError: (error: ErrorEvent, control: Control.Locate): void => {
      let message: string

      switch (error.code) {
        // User denied access to Geolocation
        // PERMISSION_DENIED
        case 1:
          message = 'Please enable \'Location\' in your browser settings.'
          break

        // Position unavailable | Safari on Simulator :kCLErrorDomain 0
        // POSITION_UNAVAILABLE
        case 2:
          message = 'Position is unavailable'
          break

        // Timed out
        // TIMEOUT
        case 3:
          message = 'Could not get geolocation data'
          break

        // Other
        default:
          message = error.message
          break
      }

      setModalContent({
        header: 'Location Error',
        body: message,
      })
    },
  })
    .addTo(map)

  if (Control.FullscreenX.isAvailable) {
    // Add fullscreen control. Actually only needed for touch devices and widget mode
    new Control.FullscreenX({
      position: 'bottomright',
      target: document.body,
    })
    .addTo(map)

    // Toggle dragging on/ off on touch device + fullscreen
    if (useScrollLock) {
      map.addEventListener('fullscreenchange', handleFullscreenXChange)
    }
  }
}

/**
 * Handle fullscreen toggle
 */
function handleFullscreenXChange({ target: map }: FullscreenXEvent) {
  // Enable touch nav
  if (map.isFullscreenX()) {
    map.dragging.enable()
    map.tap?.enable()
  // Disable touch nav
  } else {
    map.dragging.disable()
    map.tap?.disable()
  }
}

export default Map
