/**
 * Transformer for city details
 */
import type { TApiCityDetailsResponse } from '../responses/city'
import type { ICity } from '../../models/city.interfaces'

export default function transformDetails(payload: TApiCityDetailsResponse): ICity {
  return {
    id: payload.data.city.city_id,
    chapters: payload.data.chapterList.map(({ chapter_id: id, name }) => ({ id, name })),
    districts: payload.data.districtList.map(({ district_id: id, name }) => ({ id, name })),
    features: payload.data.featureList.map(({ feature_id: id, name }) => ({ id, name }))
  }
}
